<template>
  <van-popup v-model="isPopupShow" :style="{ height: '45%' }" position="bottom">
    <van-tabs v-model="active" sticky>
      <van-tab :title="skuObj.storeName + ',' + skuObj.sku">
        <van-form>
          <van-cell-group inset>
            <van-field v-model="skuObj.price" type="number" label="价格"/>
            <!-- 允许输入正整数，调起纯数字键盘 -->
            <van-field v-model="skuObj.number" type="number" label="数量"/>
            <van-field v-model="skuObj.stockNum" type="number" label="可加入库存数量"/>
            <van-field name="checkboxGroup" label="类型">
              <template #input>
                <van-checkbox-group v-model="skuObj.typeChecks" direction="horizontal">
                  <van-checkbox name="1" shape="square">发错</van-checkbox>
                  <van-checkbox name="2" shape="square">不想要</van-checkbox>
                  <van-checkbox name="3" shape="square">破损</van-checkbox>
                  <van-checkbox name="4" shape="square">不配</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field name="checkboxGroup" label="解决方案">
              <template #input>
                <van-radio-group v-model="skuObj.solution" direction="horizontal">
                  <van-radio name="1">退钱</van-radio>
                  <van-radio name="2">补发</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field v-model="skuObj.refundAmount" type="number" label="退款金额"/>
            <van-field
              v-model="skuObj.mark"
              rows="2"
              autosize
              label="备注"
              type="textarea"
              maxlength="50"
              placeholder="请输入备注"
              show-word-limit
            />
          </van-cell-group>
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit" @click="onClickButton">
              提交
            </van-button>
            <van-button round block type="danger" native-type="submit" style="margin-top: 5px"
                        v-if="skuObj.status === 0" @click="onClickButtonDelete">
              删除
            </van-button>
          </div>
        </van-form>
      </van-tab>
    </van-tabs>
  </van-popup>
</template>

<script>
import {
  Calendar,
  Card,
  Checkbox,
  CheckboxGroup,
  ContactList,
  DropdownItem,
  DropdownMenu,
  Field,
  Form,
  Radio,
  RadioGroup,
  ShareSheet,
  Tab,
  Tabs
} from 'vant';
import * as purchaseService from "@/api/admin/purchase";

export default {
  components: {
    [Card.name]: Card,
    [ContactList.name]: ContactList,
    [ShareSheet.name]: ShareSheet,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Calendar.name]: Calendar,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio
  },
  props: {
    pSkuObj: {
      type: Object
    }
  },
  data() {
    return {
      skuObj: {
        zUnitNum: 0,
        zNum: 0,
        sNum: 0,
        number: 0,
        sUnitName: '',
        zUnitName: '',
        price: 0,
        typeChecks: ['1'],
        solution: '1',
        mark: '',
        stockNum: null,
        refundAmount: null
      },
      active: 0,
      isPopupShow: false,
    };
  },
  watch: {
    pSkuObj: {
      handler(newVal) {
        console.log('watch pSkuObj handler', newVal)
        this.skuObj.zUnitNum = newVal.zUnitNum
        this.skuObj.number = newVal.number
        this.skuObj.zUnitName = newVal.zUnitName
        this.skuObj.sUnitName = newVal.sUnitName
        this.skuObj.productId = newVal.productId
        this.skuObj.sku = newVal.sku
        this.skuObj.price = newVal.price
        this.skuObj.uuid = newVal.uuid
        this.skuObj.productId = newVal.productId
        this.skuObj.sku = newVal.sku
        this.skuObj.purchasePlanUuid = newVal.purchasePlanUuid
        this.skuObj.status = newVal.status
        this.skuObj.storeName = newVal.storeName

        this.skuObj.mark = newVal.mark
        this.skuObj.stockNum = newVal.stockNum
        this.skuObj.refundAmount = newVal.refundAmount
        if (newVal.solution !== undefined && newVal.solution !== null && newVal.solution !== '') {
          this.skuObj.solution = newVal.solution
        } else {
          this.skuObj.solution = '1'
        }
        if (newVal.typeChecks !== undefined && newVal.typeChecks !== null) {
          this.skuObj.typeChecks = newVal.typeChecks
        } else {
          this.skuObj.typeChecks = ['1']
        }
      },
      immediate: true,
    }
  },
  created() {
  },
  methods: {
    onClickButton() {
      console.log('purchaseEditPopup 修改商品规格提交', this.skuObj)
      this.isPopupShow = false
      this.$emit('editSku', this.skuObj)
    },
    onClickButtonDelete() {
      const data = {
        productId: this.skuObj.productId,
        purchasePlanUuid: this.skuObj.purchasePlanUuid,
        sku: this.skuObj.sku
      }
      console.log('onClickButtonDelete', data)
      purchaseService.deleteP(data).then(res => {
        if (res.status === 200) {
          this.$emit('deletedData', data)
          this.$toast("删除成功")
          this.isPopupShow = false
        }
      })
    },
  }
};
</script>

<style lang="less">
.stock {
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #0bb20c;
}

.stockWarn {
  color: red;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
