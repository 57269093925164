import request from '@/utils/admin/request'

export function addReturn(data) {
    return request({
        url: 'charge-account/return',
        method: 'post',
        data: data
    })
}

export function getList(params) {
    return request({
        url: 'charge-account/return',
        method: 'get',
        params: params
    });
}

export function getInfo(uuid) {
    return request({
        url: 'charge-account/return/' + uuid,
        method: 'get',
    });
}

export function editSku(data) {
    return request({
        url: 'charge-account/return/edit-sku',
        method: 'post',
        data: data
    })
}

export function deleteP(data) {
    return request({
        url: 'charge-account/return/delete-product',
        method: 'delete',
        data: data
    })
}

export function deleteR(uuid) {
    return request({
        url: 'charge-account/return/' + uuid,
        method: 'delete',
    })
}

